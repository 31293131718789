.product-list {
  @extend .grid-x;
  @extend .grid-padding-x;

  background: $white;
  border-radius: 16px;
  padding: 1rem 1rem;
  margin-left: 1rem !important;
  margin-right: 1rem !important;

  .icon {
    margin-bottom: 2rem;
  }

  .product {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.usp-list {
  @extend .grid-x;
  @extend .grid-padding-x;

  margin: 2.5rem 0;
  font-weight: 500;

  >.cell {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .usp {
    font-family: $header-font-family;

    margin: 0.5rem 0.75rem;

    img {
      margin-right: 0.25rem;
    }
  }
}

.services {
  margin-bottom: 8rem;

  .services-header {
    @extend .grid-x;

    margin-bottom: 1rem;
  }

  .services-list {
    @extend .grid-x;

    .service {
      @extend .cell;
      @extend .large-4;
      @extend .small-12;

      margin-bottom: 2rem;
    }
  }
}

.complete-prototype {
  margin-bottom: 4rem;

  .complete-header {
    @extend .grid-x;

    @include breakpoint(large) {
      margin-bottom: 2rem;
    }
  }

  .complete-list {
    @extend .grid-x;

    $gap: 1rem;
    gap: $gap;

    .icon {
      margin-bottom: 2rem;
    }

    .product {
      @extend .cell;
      @extend .large-4;
      @extend .medium-12;
      @extend .small-12;

      background: #ffffff;
      border-radius: 16px;

      padding: 1rem;

      @include breakpoint(large) {
        width: calc(100% / 3 - #{$gap} * 2);
      }

      &.current {
        background: inherit;

        .more {
          display: none;
        }
      }
    }
  }
}
